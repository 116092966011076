import Footer from "../components/Footer";
import Icon from "../components/Icon";
import Logo from "../components/Logo";
import Nav from "../components/Nav";
import i18n from "../i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const Home = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  return (
    <>
      <div className="Header">
        <Logo />
        <Nav />
      </div>
      <div className="Main">
        <section className="Homepage--Intro">
          <h1>{t("pages:home.intro.title")}</h1>
          <p>{t("pages:home.intro.description")}</p>
          <a href={`/${language}/start`}>{t("dictionary.start")}</a>
        </section>
        <section className="Homepage--TextColumns">
          <strong>{t("pages:home.block2.label")}</strong>
          <h2>{t("pages:home.block2.title")}</h2>
          <ul>
            {t("pages:home.block2.items", { returnObjects: true }).map((item) => (
              <li>
                <strong>{item.title}</strong>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
          <small>{t("pages:home.block2.footer")}</small>
        </section>
        <section className="Homepage--quotes">
          <strong></strong>
          <h2>{t("pages:home.block3.title")}</h2>
          <p>{t("pages:home.block3.text")}</p>
          <ul>
            {t("pages:home.block3.items", { returnObjects: true }).map((item) => (
              <li>
                <Icon name={item.icon} />
                <blockquote>
                  <b>{item.title} </b>
                  {item.text}
                </blockquote>
              </li>
            ))}
          </ul>
        </section>

        <section className="Homepage--faq">
          <h2>{t("pages:home.faq.title")}</h2>

          <FAQ />
        </section>
      </div>
      <Footer />
    </>
  );
};
function FAQ() {
  const [isOpen, setIsOpen] = useState(null);
  const toggleOpen = (id) => () => setIsOpen((isOpen) => (isOpen === id ? null : id));
  const { t } = useTranslation();
  return (
    <div className="accordion">
      {t("pages:home.faq.items", { returnObjects: true }).map(({ title, text }, index) => (
        <Question
          key={index}
          content={text}
          title={title}
          isOpen={isOpen === index}
          toggle={toggleOpen(index)}
        />
      ))}
    </div>
  );
}
const Question = ({ title, content, isOpen, toggle }) => {
  return (
    <div className="accordion-item">
      <button className="accordion-title" onClick={toggle}>
        {isOpen ? <Icon name={"minus"} /> : <Icon name={"plus"} />}
        {title}
      </button>
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        className={isOpen ? "accordion-content isOpen" : "accordion-content "}
      ></div>
    </div>
  );
};
export default Home;
