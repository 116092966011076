import Footer from "../components/Footer";
import Logo from "../components/Logo";
import Nav from "../components/Nav";
import { useTranslation } from "react-i18next";
const Datenschutz = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="Header">
        <Logo />
        <Nav />
      </div>
      <div className="Main">
        <section className="SimplePage">
          <h1>{t("pages:dataProtection.title")}</h1>
          <div id="cookiefirst-policy-page"></div>
          <div>
            {t("pages:dataProtection.cookieGuideline")}{" "}
            <a href="https://cookiefirst.com/de/consent-management-platform/">
              CookieFirst - Consent Management Platform
            </a>
            .
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default Datenschutz;
