import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import pagesEN from "./locale/en/pages.json";
import pagesDE from "./locale/de/pages.json";
import commonEN from "./locale/en/common.json";
import commonDE from "./locale/de/common.json";

const resources = {
  en: {
    common: commonEN,
    pages: pagesEN,
  },
  de: {
    common: commonDE,
    pages: pagesDE,
  },
};

i18n
  .use(Backend) // used to load data from othe directory
  .use(LanguageDetector) // detects the current language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    whitelist: ["de", "en"],
    fallbackLng: ["de"],
    supportedLngs: ["de", "en"],
    defaultNS: "common",
    detection: {
      order: ["path", "localStorage", "cookie", "navigator"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    debug: false,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    resources: resources,
  });

export default i18n;
