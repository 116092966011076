import Logo from "../components/Logo";
import Nav from "../components/Nav";
import sanitizeInviteCode from "../services/sanitizeInviteCode";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { verifyInviteCode } from "../services/firebase";
const Start = () => {
  const [formError, setFormError] = useState(false);
  const inviteCodeInput = useRef();
  const { t } = useTranslation();
  const onInviteSubmit = (e) => {
    e.preventDefault();
    const inviteCode = sanitizeInviteCode(inviteCodeInput.current.value);
    if (inviteCode.length > 3) {
      verifyInviteCode(inviteCode)
        .then((result) => {
          if (result) {
            setFormError(false);
            window.sessionStorage.setItem("invite code", inviteCode);
            window.sessionStorage.setItem("employer", result.employer);
            window.location = "survey";
          } else {
            setFormError(t("pages:start.error.invalidCode"));
          }
        })
        .catch((e) => {
          setFormError(t("pages:start.error.invalidCode"));
        });
    } else {
      setFormError(t("pages:start.error.incompleteCode"));
    }
  };

  return (
    <>
      <div className="Header">
        <Logo />
        <Nav />
      </div>
      <div className="Main">
        <div className="InviteForm">
          <strong>{t("dictionary.enterInviteCode")}</strong>
          <form className="InviteForm--Form" onSubmit={onInviteSubmit}>
            <input ref={inviteCodeInput} />
            <button type="submit">{t("dictionary.start")}</button>
          </form>
          {formError && <p className="error">{formError}</p>}
        </div>
      </div>
    </>
  );
};

export default Start;
