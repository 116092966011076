import Footer from "../components/Footer";
import Logo from "../components/Logo";
import Nav from "../components/Nav";
import { useTranslation } from "react-i18next";
const Employees = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="Header">
        <Logo />
        <Nav />
      </div>
      <div className="Main">
        <section className="SimplePage">
          <h1>{t("pages:employees.intro.title")}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: t("pages:employees.intro.text", {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </section>
      </div>
      <Footer />
    </>
  );
};
export default Employees;
