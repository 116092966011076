import Logo from "../components/Logo";
import Nav from "../components/Nav";
import React, { useEffect } from "react";
import Results from "../Results";
import i18n from "../i18n";
import parseUrl from "parse-url";
import { FormId, DirtyInviteCode, ResponseId } from "../types";
import { Widget } from "@typeform/embed-react";
import { calculateAverageFromKeys, calculateMyself } from "../calculation";
import { saveResult, getTypeFormResponseProxy } from "../services/firebase";
import { useTranslation } from "react-i18next";

const { useState } = require("react");
function isSandboxMode() {
  return window.location.href.includes("sandbox");
}
function isDebugMode() {
  return window.location.href.includes("debug");
}
function getDebugResponseId() {
  const parsed = parseUrl(window.location.href);
  return parsed.query.debug;
}
// not necessarily the full response, just the fields we care about:
interface TypeformSubmitResponse {
  response_id: ResponseId;
  responseId: ResponseId;
  formId: FormId;
}
function Survey() {
  const { t } = useTranslation();
  const [language] = useState(i18n.language);
  const [results, setResults] = useState(null);
  const [average, setAverage] = useState(null);
  const inviteCode = window.sessionStorage.getItem("invite code") as DirtyInviteCode;
  const employer = window.sessionStorage.getItem("employer");
  const formId = t("typeformID", language) as FormId;
  async function handleSubmit(res: TypeformSubmitResponse) {
    const data = await getTypeFormResponseProxy(res.responseId, formId);
    if (isDebugMode()) {
      console.log(res);
    }
    const results = calculateMyself(data.variables);
    const average = calculateAverageFromKeys(data.variables);
    await saveResult(inviteCode, res.responseId, {formId});
    setResults(results);
    setAverage(average);
  }
  async function debug(responseId: ResponseId) {
    const data = await getTypeFormResponseProxy(responseId, formId);
    const results = calculateMyself(data.variables);
    const average = calculateAverageFromKeys(data.variables);
    setResults(results);
    setAverage(average);
  }
  useEffect(() => {
    if (!isDebugMode() && !isSandboxMode() && !inviteCode) {
      window.location.assign("/");
    }
    if (isDebugMode()) {
      console.log("debug mode active!");
      const responseId = getDebugResponseId();
      if (responseId) {
        console.log(`using response ID: ${responseId}`);
        debug(responseId);
      } else {
        console.log("no response ID given");
      }
    }
    if (isSandboxMode()) {
      console.log("sandbox mode active. surveys aren't being stored, nor are response IDs given");
    }
  }, []);
  // be sure to only load widget if we definitely have an invite code
  const safeToLoadWidget = !!inviteCode;
  return (
    <>
      {results ? (
        <div>
          <div className="Header">
            <Logo />
            <Nav />
          </div>
          <Results results={results} average={average} />
        </div>
      ) : (
        <div className="TypeformWidget">
          {isSandboxMode() && (
            <p>Sandbox Mode Active! Survey's aren't being stored, nor are response IDs given!</p>
          )}
          {isDebugMode() && <p>Debug mode active. See browser console.</p>}
          {safeToLoadWidget && (
            <Widget
              id={formId}
              className="TypeformWidget--Embed"
              enableSandbox={isSandboxMode()}
              onSubmit={handleSubmit}
              hidden={{
                invite_code: inviteCode, // typeform only accepts lowercase
                employer,
              }}
            />
          )}
        </div>
      )}
    </>
  );
}
export default Survey;
